import * as React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Hero from '../components/hero';
import DemoForm from '../components/demoform';
import ContactList from '../components/contactlist';
import './demo.scss';

// markup
const DemoPage = () => {
  return (
    <Layout>
       <Helmet>
            <title>Delfi.se - Boka demo</title>
        </Helmet>
        <Hero heading="Boka demo" 
              modifiers={{
                  'hero--left': true
              }}>
            <ul className="hero__list">
                <li>Kostnadsfri rådgivning</li>
                <li>Offert & prisförslag</li>
                <li>Så kan våra tjänster hjälpa dig</li>
            </ul>
        </Hero>
        <div className="demo">
              <div className="demo__col demo__col--primary">
                <div className="demo__content">
                    <h1 className="demo__heading">Så går ett demomöte till</h1>
                    <p>
                      Med lång erfarenhet av dagligvarubranschen och specialistkompetens inom marknadsinformation står vår personal redo att hjälpa er inför era utmaningar. Vi lyssnar till dina behov och hjälper dig att hitta rätt lösningar.
                    </p>
                    <p>
                      Vi demonstrerar gärna våra tjänster för dig på ett Teamsmöte. Ser fram emot att träffa dig!
                    </p>
                </div>
                <ContactList />
              </div>
              <div className="demo__col">
                <div className="demo__col-inner">
                    <DemoForm />
                </div>
              </div>
        </div>
        
    </Layout>
  )
}

export default DemoPage;