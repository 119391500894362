import React from 'react';
import anders from '../../images/persons/anders@2x.jpg';
import ann from '../../images/persons/ann@2.jpg';
import joakim from '../../images/persons/joakim@2x.jpg';
import './styles.scss';

const ContactList = props => {
    return (
        <div className="contact-list">
            <div className="contact-list__item"> 
                <div className="contact-list__item-image">
                    <img src={anders} alt="" />
                </div>
                <div className="contact-list__item-content">
                    <h2 className="contact-list__item-name">Anders Strandsten</h2>
                    <a className="contact-list__item-email" href="mailto:anders.strandsten@delfi.se">anders.strandsten@delfi.se</a>
                    <span className="contact-list__item-phone">08-522 243 09</span>
                </div>
            </div>
            <div className="contact-list__item"> 
                <div className="contact-list__item-image">
                    <img src={joakim} alt="" />
                </div>
                <div className="contact-list__item-content">
                    <h2 className="contact-list__item-name">Joakim Browall</h2>
                    <a className="contact-list__item-email" href="mailto:joakim.browall@delfi.se">joakim.browall@delfi.se</a>
                    <span className="contact-list__item-phone">08-522 243 08</span>
                </div>
            </div>
            <div className="contact-list__item"> 
                <div className="contact-list__item-image">
                    <img src={ann} alt="" />
                </div>
                <div className="contact-list__item-content">
                    <h2 className="contact-list__item-name">Ann Tindberg</h2>
                    <a className="contact-list__item-email" href="mailto:ann.tindberg@delfi.se">ann.tindberg@delfi.se</a>
                    <span className="contact-list__item-phone">08-522 243 03</span>
                </div>
            </div>
        </div>
    );
};

export default ContactList;