import React, { Component } from 'react';
import classNames from 'classnames';
import Checkbox from '../checkbox';
import Input from '../input';
import Textarea from '../textarea';
import SaveButton from '../savebutton';
import './styles.scss';


class DemoForm extends Component {
    
    state = {
        isValid: true,
        isLoading: false,
        isSaved: false,
        emailIsValid: true,
        nameIsValid: true,
        mail: {
            interestedInRegister: false,
            interestedInStatistics: false,
            interestedInDabas: false,
            interestedInOther: false,
            name: '',
            email: '',
            phone: '',
            other: ''
        }
    }

    setParam(name, value) {
        const mail = { ...this.state.mail };
        mail[name] = value;
        this.setState({
            mail: mail
        });
    }

    buildMailBody() {
        let body = [];
        body.push('Vilka tjänster är du intresserad av?');
        if (this.state.mail.interestedInRegister) {
            body.push('Butiksregister & kartverktyg');
        }

        if (this.state.mail.interestedInStatistics) {
            body.push('Branschstatistik');
        }

        if (this.state.mail.interestedInDabas) {
            body.push('Artikeldatabas');
        }

        if (this.state.mail.interestedInOther) {
            body.push('Annat');
        }

        body.push(`Namn: ${this.state.mail.name}`);
        body.push(`Email: ${this.state.mail.email}`);
        body.push(`Phone: ${this.state.mail.phone}`);
        body.push(`Annat: ${this.state.mail.other}`);

        return body.join('\n\n');  
    }

    async sendMail(e) {
        e.preventDefault();

        if (this.state.mail.name === '' && this.state.mail.email === '') {
            this.setState({
                isValid: false,
                nameIsValid: this.state.mail.name !== '',
                emailIsValid: this.state.mail.email !== ''
            });
        } else {
            this.setState({
                isLoading: true
            });
    
            //Build mail body
            const body = this.buildMailBody();
            
            const res = await fetch('/api/message', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    subject: 'Mötesförfrågan från Delfi.se',
                    mailBody: body
                })
            });
    
            await res.json();
            this.setState({
                isLoading: false,
                isSaved: true,
                isValid: true,
                emailIsValid: true,
                nameIsValid: true,
                mail: {
                    interestedInRegister: false,
                    interestedInStatistics: false,
                    interestedInDabas: false,
                    interestedInOther: false,
                    name: '',
                    email: '',
                    phone: '',
                    other: ''
                }
            }, () => {
                setTimeout(() => {
                    this.setState({ 
                        isSaved: false 
                    });
                }, 6000);
            });
        }
    }

    render() {
        return (
            <form className={classNames({
                'demo-form': true,
                'demo-form--is-loading': this.state.isLoading
            })} method="post" onSubmit={e => this.sendMail(e)}>
                <div className="demo-form__container">
                    <h2 className="demo-form__heading">Vilka tjänster är du intresserad av?</h2>
                    <div className="demo-form__subject">
                        <label className="demo-form__subject-item">
                            <Checkbox name="interestedInRegister" onChange={e => this.setParam('interestedInRegister', !this.state.mail.interestedInRegister)} checked={this.state.mail.interestedInRegister} /> Butiksregister & kartverktyg
                        </label>
                        <label className="demo-form__subject-item">
                            <Checkbox name="interestedInStatistics" onChange={e => this.setParam('interestedInStatistics', !this.state.mail.interestedInStatistics)} checked={this.state.mail.interestedInStatistics} /> Branschstatistik
                        </label>
                        <label className="demo-form__subject-item">
                            <Checkbox name="interestedInDabas" onChange={e => this.setParam('interestedInDabas', !this.state.mail.interestedInDabas)} checked={this.state.mail.interestedInDabas} /> Artikeldatabas
                        </label>
                        <label className="demo-form__subject-item">
                            <Checkbox name="interestedInOther" onChange={e => this.setParam('interestedInOther', !this.state.mail.interestedInOther)} checked={this.state.mail.interestedInOther} /> Annat
                        </label>
                    </div>
        
                    <h2 className="demo-form__heading">Dina kontaktuppgifter</h2>
                    <label className="demo-form__label">Namn *</label>
                    <Input modifiers={{
                        'form__input--is-error': !this.state.nameIsValid
                    }} id="name" name="name" type="text" value={this.state.mail.name} onChange={(e) => this.setParam('name', e.currentTarget.value)} />
        
                    <label className="demo-form__label">E-post *</label>
                    <Input modifiers={{
                        'form__input--is-error': !this.state.emailIsValid
                    }} id="email" name="email" type="email" value={this.state.mail.email} onChange={(e) => this.setParam('email', e.currentTarget.value)} />
        
                    <label className="demo-form__label">Telefonnummer</label>
                    <Input name="phone" type="text" value={this.state.mail.phone} onChange={(e) => this.setParam('phone', e.currentTarget.value)}  />
        
                    <label className="demo-form__label">Övrigt</label>
                    <Textarea name="other" value={this.state.mail.other} onChange={(e) => this.setParam('other', e.currentTarget.value)} />
        
                    <p className="demo-form__text">Vi kommer att kontakta dig för att boka en lämplig tid för mötet.</p>

                    {!this.state.isValid &&
                        <p className="demo-form__text demo-form__text--error">Namn och e-post är obligatoriska fält</p>
                    }

                    <SaveButton
                        type="submit"
                        isLoading={this.state.isLoading}
                        isSaved={this.state.isSaved} 
                        text="Skicka mötesförfrågan" 
                        textSaving="Skickar"
                        textSaved="Tack för din förfrågan!"
                        attr={{
                            disabled: this.props.isSaving
                        }}
                        modifiers={{
                            'button--large': true,
                            'demo-form__submit': true
                        }}>Skicka mötesförfrågan</SaveButton>
                </div>
            </form>
        );
    }
};

export default DemoForm;