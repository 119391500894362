import React from 'react';
import classNames from 'classnames';
import './styles.scss';

const Hero = props => {
    return (
        <div className={classNames({
            'hero': true,
            'hero--has-arrow': props.arrow,
            ...props.modifiers
        })}>
            <div className="hero__image"></div>
            <div className="hero__dots"></div>
            <svg viewBox="0 0 100 25">
                <path fill="#FFFFFF" d="M0 30 V12 Q30 17 55 12 T100 11 V30z" />
            </svg>
            <div className="hero__content">
                <h1 className="hero__heading">{props.heading}</h1>
                {props.children}
            </div>
        </div>
    );
};

export default Hero;